import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../shared.service';
import { HostListener } from "@angular/core";
import { TokenStorageService } from 'src/app/_services/token-storage.service';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {


  hamburgerClass: boolean = false;

  screenHeight: any;
  screenWidth: any;
  imgheader: any;

    constructor(
      private sharedService: SharedService,
      private tokenStorage: TokenStorageService) {
        this.getScreenSize();
    }

  ngOnInit(): void {
    this.getUserData();
  }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  isCustomer:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res, 'res')
      // console.log(res.shipper, 'res1')
      if(res.shipper.length == 0){
        this.imgheader = 'assets/images/logo-full.png';
      }else{
        this.imgheader = 'https://apiexpl.hallo.or.id/uploads/images/shippers/'+res.shipper[0].images;
      }
      // console.log(res.shipper[0].images, 'res2')
      // // console.log(res.shipper[0].address, 'res')
		  // this.usersData.role.forEach((e:any) => {
			//   this.rolesString = this.rolesString+e.name+', ';
		  // });
			// // console.log(this.rolesString)
      // if(this.rolesString.includes('Admin')) {
      //   this.isAdmin = true;
      // }
      // if(this.rolesString.includes('Superadmin')) {
      //   this.isSuperadmin = true;
      // }
			// console.log(this.isAdmin)
    })
  }

  toggleHamburgerClass(){
	this.hamburgerClass = this.sharedService.toggleHamburgerClass();
  }

   @HostListener('window:resize', ['$event'])
    getScreenSize() {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        const div =  document.getElementById('main-wrapper');
        if(this.screenWidth <768) {
            document.body.setAttribute('data-sidebar-style', 'overlay');
            // document.body.setAttribute('data-sidebar-style', 'mini');
        } else if(this.screenWidth >=768 && this.screenWidth <=1023) {
            document.body.setAttribute('data-sidebar-style', 'mini');
        } else {
            document.body.setAttribute('data-sidebar-style', 'full');
            // document.body.setAttribute('data-sidebar-style', 'mini');
        }
    }

}
