import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../_services/master.service';
import { TokenStorageService } from '../../_services/token-storage.service';


@Component({
  selector: 'app-index1',
  templateUrl: './index1.component.html',
  styleUrls: ['./index1.component.css']
})
export class Index1Component implements OnInit {

  @Input() data:any;

  datenow = new Date();

  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  tutorials: any;
  totalpayments: any;
  totalbill: any;
  totalinbh: any;
  totalinbb: any;
  totalinbt: any;
  totalshpph: any;
  totalshpph1: any;
  totalshppb: any;
  totalshppb1: any;
  totalshppt: any;
  totalshppt1: any;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService,
  ) {}

  usersData:any = {};
    rolesString:any = '';
    isAdmin:boolean = false;
    isSuperadmin:boolean = false;
    isCustomer:boolean = false;
    isAgen:boolean = false;
    isFinance:boolean = false;
    isCleansings:boolean = false;
    isInbound:boolean = false;
    getUserData() {
      this.tokenStorage.getUsers().then(async res => {
        this.usersData = res;
        // console.log(res)
        this.usersData.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
        });
        // console.log(this.rolesString)
        if(this.rolesString.includes('Admin')) {
          this.isAdmin = true;
        }
        if(this.rolesString.includes('Superadmin')) {
          this.isSuperadmin = true;
        }
        if(this.rolesString.includes('Agen')) {
          this.isAgen = true;
        }
        if(this.rolesString.includes('Finance')) {
          this.isFinance = true;
        }
        if(this.rolesString.includes('Cleansings')) {
          this.isCleansings = true;
        }
        if(this.rolesString.includes('Inbound')) {
          this.isInbound = true;
        }
        // console.log(this.isAdmin)
      })
    }

  ngOnInit(): void {
    this.retrieveInbounds();
    this.retrieveInboundbulan();
    this.retrieveInboundtahun();
    this.retrieveShipmenthari();
    this.retrieveShipmentbulan();
    this.retrieveShipmenttahun();
    this.retrieveTutorials();
    this.retrieveTagihans();
    this.getUserData();
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/pembayarans', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response.rows)
        let rdata = response.rows
        let sumpembayarans = 0;
        for (let i = 0; i < rdata.length; i++) {
          // const element = rdata[i];
          // console.log(rdata[i].totalbayar)
          sumpembayarans += rdata[i].totalbayar;
          
        }
        // for (i = 0; i < response.rows.length; i++) {
        //   // bulanKyw.push(data[i].bulan);
        //     totalKyw.push(parseInt(data[i].total == undefined ? 0 : data[i].total));
        // };

        // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
        this.totalpayments = this.currencyStr(sumpembayarans)
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });
  };

  retrieveTagihans(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;

    this.tutorialService.getAlls('dashboards/tagihans', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        let rdata = response.rows
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          // const element = rdata[i];
          // console.log(rdata[i].totalbayar)
          sumtagihan += rdata[i].totaltagihan;
          
        }
        // for (i = 0; i < response.rows.length; i++) {
        //   // bulanKyw.push(data[i].bulan);
        //     totalKyw.push(parseInt(data[i].total == undefined ? 0 : data[i].total));
        // };

        // this.totalpayments = this.currencyStr(response.rows[0].totalbayar)
        this.totalbill = this.currencyStr(sumtagihan)
        // this.totalbill = this.currencyStr(response.rows[0].totaltagihan)
        // console.log(response.rows[0].totalbayar);
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInbounds(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundshari', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbh = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInboundbulan(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundsbulan', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbb = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };

  retrieveInboundtahun(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getInboundstahun', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        for (let i = 0; i < rdata.length; i++) {
          sumtagihan += rdata[i].totalinbound;
        }
        this.totalinbt = sumtagihan
      },
      error => {
        // console.log(error);
      });
  };
  retrieveShipmenthari(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentshari', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
          }else{
            sumtagihan1 += rdata[i].totalshipment;
          }
        }
        this.totalshpph = sumtagihan
        this.totalshpph1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  retrieveShipmentbulan(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentsbulan', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          // console.log(rdata[i].consignee_id == null,'rdata[i]rdata[i]')
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
          }else{
            sumtagihan1 += rdata[i].totalshipment;
          }
        }
        this.totalshppb = sumtagihan
        this.totalshppb1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  retrieveShipmenttahun(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    params.tglinput = this.datenow;
    // params.tglinput = "2023-04-20";

    this.tutorialService.getAlls('dash/getShipmentstahun', params)
    .subscribe(
      response => {
        let rdata = response;
        let sumtagihan = 0;
        let sumtagihan1 = 0;
        for (let i = 0; i < rdata.length; i++) {
          if(rdata[i].consignee_id == null){
            sumtagihan += rdata[i].totalshipment;
            console.log(sumtagihan,'sumtagihan')
          }else{
            sumtagihan1 += rdata[i].totalshipment;
            console.log(sumtagihan1,'sumtagihan1')
          }
        }
        this.totalshppt = sumtagihan
        this.totalshppt1 = sumtagihan1
      },
      error => {
        // console.log(error);
      });
  };

  currencyStr(value: any) {
      var formatter = new Intl.NumberFormat('en-US');
      return formatter.format(value);
  };

//   coinHolding = [
//     {
//       image: "assets/images/coin/coin_holding/1.svg",
//       name: "Shipment",
//       short_name: "ETH",
//       price: "$168,331.09",
//       up_down: "45% This Week",
//       up_down_image: "assets/images/svg/chevron_up.svg",
//       buy: "$5,673",
//       sell: "$5,982",
//       border_class: "border-secondary",
//       bg_class: "bg-secondary",
//     },
//     {
//       image: "assets/images/coin/coin_holding/2.svg",
//       name: "Bitcoin",
//       short_name: "BTC",
//       price: "$168,331.09",
//       up_down: "45% This Week",
//       up_down_image: "assets/images/svg/chevron_up.svg",
//       buy: "$5,673",
//       sell: "$5,982",
//       border_class: "border-warning",
//       bg_class: "bg-warning",
//     },
//     {
//       image: "assets/images/coin/coin_holding/3.svg",
//       name: "Digital Cash",
//       short_name: "DASH",
//       price: "$168,331.09",
//       up_down: "45% This Week",
//       up_down_image: "assets/images/svg/chevron_up.svg",
//       buy: "$5,673",
//       sell: "$5,982",
//       border_class: "border-info",
//       bg_class: "bg-info",
//     },
//     {
//       image: "assets/images/coin/coin_holding/4.svg",
//       name: "Zcash",
//       short_name: "ZEC",
//       price: "$168,331.09",
//       up_down: "45% This Week",
//       up_down_image: "assets/images/svg/chevron_up.svg",
//       buy: "$5,673",
//       sell: "$5,982",
//       border_class: "border-primary",
//       bg_class: "bg-primary",
//     },
// ];

//   quickTransfer = [
//         {
//           name: "Samuel",
//           username: "@sam224",
//           image: "assets/images/contacts/1.jpg",
//         },
//         {
//           name: "Cindy",
//           username: "@cindyss",
//           image: "assets/images/contacts/2.jpg",
//         },
//         {
//           name: "David",
//           username: "@davidxc",
//           image: "assets/images/contacts/3.jpg",
//         },
//         {
//           name: "Martha",
//           username: "@marthaa",
//           image: "assets/images/contacts/4.jpg",
//         },
//         {
//           name: "Olivia",
//           username: "@oliv62",
//           image: "assets/images/contacts/5.jpg",
//         },
//   ];

}
