<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
        <p>Copyright © Solustik 2022</p>
        <!-- <p>Copyright ©  <a href="javascript:void(0);"></a> 2022</p> -->
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->
