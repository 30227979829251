<!--**********************************
    Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Master</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" (click)="reloadPage()">Shippers</a></li>
          </ol>
        </div>

      </div>


    </div>

    <div class="d-flex align-items-center flex-wrap mb-3">
      <div class="input-group search-area-2 mb-3 mr-auto d-inline-flex">
        <input type="text" class="form-control" placeholder="Search by title" [(ngModel)]="title" />
        <div class="input-group-append">
          <button class="btn" type="button" (click)="searchTitle()">
            <i class="flaticon-381-search-2"></i>
          </button>
        </div>
      </div>
      <a href="javascript:void(0);" (click)="reloadPage()" class="btn btn-danger btn-rounded mb-3 mr-3"><i class="fa fa-refresh scale5 mr-2"></i>Refresh</a>
      <a href="javascript:void(0);" class="btn btn-primary btn-rounded mb-3 mr-3" (click)="open(sendMessageModal)"><i class="fa fa-plus scale5 mr-2"></i>Create</a>
      <a href="javascript:void(0);" class="btn btn-info btn-rounded mb-3 mr-3" (click)="opensethargashipper(sendMessageModalhgr)"><i class="fa fa-book scale5 mr-2"></i>Set Harga</a>
      <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded mb-3 mr-3" (click)="openimport(sendModalImport)"><i class="fa fa-cloud-upload scale5 mr-2"></i>Import</a>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">List Shippers</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="">
                        <input type="checkbox" name="all" [checked]="isAllChecked()" (change)="checkAll($event)"/>
                    </th>
                    <th scope="col">No</th>
                    <th scope="col">First Code Resi</th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Kota</th>
                    <!-- <th scope="col">Template</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tutorial of tutorials; index as x">
                    <td>
                          <input type="checkbox" name="tutorialcb[]" value="{{tutorial.id}}" [(ngModel)]="tutorial.state"
                          (click)="check(tutorial)"/>
                        </td>
                    <th scope="row">
                      {{ x+1 }}
                    </th>
                    <td>
                      {{ tutorial.code_resi }}
                    </td>
                    <td>
                      {{ tutorial.code }}
                    </td>
                    <td>{{ tutorial.name }}</td>
                    <td>{{ tutorial.phone_number }}</td>
                    <td>{{ tutorial.address }}</td>
                    <td>{{ tutorial.kota }}</td>
                    <!-- <td>{{ tutorial.template_option }}</td> -->
                    <td>
                      <!-- <button type="button" class="btn btn-rounded btn-success" (click)="editUser(sendMessageModal, tutorial, 'view')"><span
                            class="btn-icon-left text-success"><i class="fa fa-eye color-success"></i>
                            </span>Detail</button>&nbsp; -->

                      <button type="button" class="btn btn-rounded btn-info"
                        (click)="editUser(sendMessageModal, tutorial, 'update')"><span
                          class="btn-icon-left text-info"><i class="fa fa-pencil color-info"></i>
                        </span>Edit</button>&nbsp;
                        <button type="button" class="btn btn-rounded btn-danger" (click)="deleteShippers(tutorial)"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button>&nbsp;
                          <button type="button" class="btn btn-rounded btn-dark" [routerLink]="['/admin/app-mst-shippersprice/', tutorial.id ]"><span
                            class="btn-icon-left text-dark"><i class="fa fa-tags color-dark"></i>
                            </span>Price List</button>
                      <!-- <button type="button" class="btn btn-rounded btn-danger"><span
                          class="btn-icon-left text-danger"><i class="fa fa-trash-o color-danger"></i>
                          </span>Remove</button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex flex-wrap justify-content-between p-2">
              <ngb-pagination [collectionSize]="count" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)">
              </ngb-pagination>
              <div class="form-group">
                <label class="mr-sm-2">Show:</label>
                <select class="mr-sm-2 custom-select" style="width: auto" name="pageSize"
                  (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
                entries
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!--**********************************
  Content body end
***********************************-->
<!-- Modal Harga -->
<!-- SET IMPORT -->
<ng-template #sendModalImport let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Import</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<!-- <form class="comment-form"> -->
			<div class="row">

        <div class="col-lg-4 mb-3">
          <label class="text-black font-w600">Select file to upload.</label>
          <input type="file" class="form-control" (change)="onFileChange($event);">
        </div>

				<div class="col-lg-12">
					<div class="form-group mb-0">
            <button (click)="Downloadtemps()" class="submit btn btn-success mr-3">Download Template</button>
            <button (click)="Upload()" class="submit btn btn-danger mr-3">Upload File</button>
					</div>
				</div>
			</div>
		<!-- </form> -->
	</div>
</ng-template>

<!-- SET STATUS TRACKING -->
<ng-template #sendMessageModalhgr let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Form Set Harga</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
    <!-- <ngb-alert [dismissible]="false" type="danger">
      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="mr-2"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
      <strong>This tracking status will be applicable to all cleansing inside the selected manifest</strong>
    </ngb-alert> -->

		<form class="comment-form">
			<div class="row">

        <div class="col-12">
          <div class="form-group">
            <label class="form-label w-100"
              >Country <span class="text-danger">*</span></label
            >
<!--
            <p-multiSelect [options]="typeItems" [(ngModel)]="selectedTypeItem" defaultLabel="Select a City" optionLabel="name"
	display="chip"></p-multiSelect>

  <p-multiSelect [options]="cities" [(ngModel)]="selectedCities" defaultLabel="Select a City" optionLabel="name" selectedItemsLabel="{0} items selected"></p-multiSelect> -->

  <!-- <p-multiSelect
    [options]="typeItems"
    [(ngModel)]="selectedTypeItem"
    [optionLabel]="name"
    [selectionLimit]="10"
    display="chip"
    [showHeader]=false
    (onChange)="onDropdownChange($event, 'type_id')">
</p-multiSelect> -->

            <p-multiSelect
              [options]="typeItems"
              optionLabel="name"
              [filter]="true"
              [disabled]="action == 'view'"
              filterBy="name"
              styleClass="w-100"
              [(ngModel)]="selectedTypeItem"
              [ngModelOptions]="{standalone: true}"
              placeholder="Select a Type"
              (onChange)="onDropdownChange($event, 'type_id')"
              (onClear)="onDropdownClear('type_id')"
            >
              <ng-template pTemplate="selectedItem">
                <div class="" *ngIf="selectedTypeItem">
                  <div>{{ selectedTypeItem.name }}</div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="">
                  <div>{{ item.name }}</div>
                </div>
              </ng-template>
            </p-multiSelect>
          </div>
        </div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Weight <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="weight"
              required
              [(ngModel)]="weight"
              #name="ngModel"
              [disabled]="action == 'view'"
              name="weight"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Purchase price <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="purc_price"
              required
              [(ngModel)]="purc_price"
              #name="ngModel"
              [disabled]="action == 'view'"
              name="purc_price"
            />
          </div>
				</div>

        <div class="col-lg-12">
					<div class="form-group">
						<label class="text-black font-w600">Selling price <span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              id="sell_price"
              required
              [(ngModel)]="sell_price"
              #name="ngModel"
              [disabled]="action == 'view'"
              name="sell_price"
            />
          </div>
				</div>

        <!-- <div class="col-lg-12 mb-3">
          <label class="text-black font-w600">Pilih Status <span class="required">*</span></label>
              <select class="form-control default-select" id="status" [(ngModel)]="status" #name="ngModel" name="status">
                <option ng-value="">Please select</option>
                <option ng-value="MANIFESTED">MANIFESTED</option>
                <option ng-value="CLEREANCE AT ORIGIN">CLEREANCE AT ORIGIN</option>
                <option ng-value="TRANSIT AT ORIGIN">TRANSIT AT ORIGIN</option>
                <option ng-value="ARRIVED AT DESTINATION">ARRIVED AT DESTINATION</option>
                <option ng-value="TRANSIT AT DESTINATION">TRANSIT AT DESTINATION</option>
                <option ng-value="OUT FOR DELIVERY">OUT FOR DELIVERY</option>
                <option ng-value="TROUBLE">TROUBLE</option>
                <option ng-value="DELIVERED">DELIVERED</option>
              </select>
        </div> -->

				<div class="col-lg-12" *ngIf="fadd_role.action != 'view'">
					<div class="form-group mb-0">
            <button (click)="setsaveHarga()" class="submit btn btn-primary">Submit</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</ng-template>


<!-- Modal ADD -->
<ng-template #sendMessageModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Form Shipper</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- START FORM -->
    <div class="form-validation">
      <form class="form-valide" action="#" method="post" [formGroup]="angForm" novalidate>
        <div class="row">
          <div class="col-xl-12">

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="code">First Code Resi
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="code_resi" formControlName="code_resi" placeholder="Enter a code resi.." [(ngModel)]="code_resi"
                >
                <div
                  *ngIf="angForm.controls['code_resi'].invalid && (angForm.controls['code_resi'].dirty || angForm.controls['code_resi'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['code_resi'].errors.required">
                    Code Resi is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="code">Code
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="code" formControlName="code" placeholder="Enter a code.." [(ngModel)]="code"
                >
                <div
                  *ngIf="angForm.controls['code'].invalid && (angForm.controls['code'].dirty || angForm.controls['code'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['code'].errors.required">
                    Code is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="name">Name
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="name" formControlName="name"
                  placeholder="Enter a name.." [(ngModel)]="name">
                <div
                  *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['name'].errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-12 row"> -->
              <div class="form-group row">
                <label class="form-label col-lg-4">Phone Number</label>
                <!-- <div class="input-group mb-3 col-lg-8"> -->
                <div class="input-group col-lg-8">
                  <div class="input-group-prepend" ngbDropdown>
                    <button
                      class="btn btn-primary dropdown-toggle d-flex justify-content-between"
                      type="button"
                      [disabled]="action == 'view'"
                      data-toggle="dropdown"
                      ngbDropdownToggle
                    >
                      <img
                        [src]="
                          'http://' +
                          selectedShipperPhoneNumberCountry?.flag
                        "
                        *ngIf="selectedShipperPhoneNumberCountry?.flag"
                        (error)="
                          selectedShipperPhoneNumberCountry.flag =
                            '/assets/images/qr.png'
                        "
                        width="30"
                        class="mr-2"
                      />
                      <span *ngIf="selectedShipperPhoneNumberCountry?.phonecode">+{{
                        selectedShipperPhoneNumberCountry?.phonecode
                      }}</span>
                      <!-- </ng-template> -->
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <p-dropdown
                      [options]="countries"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [disabled]="action == 'view'"
                      [showClear]="true"
                      [(ngModel)]="selectedShipperPhoneNumberCountry"
                      [ngModelOptions]="{standalone: true}"
                      styleClass="w-100"
                      placeholder="Select a Phonecode"
                      (onChange)="onDropdownChange($event, 'shipper_phone')"
                      (onClear)="onDropdownClear('shipper_phone')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedShipperPhoneNumberCountry">
                          <div>+{{ selectedShipperPhoneNumberCountry.phonecode }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>+{{item.phonecode}} {{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                    </div>
                  </div>
                  <input type="text" class="form-control" id="phone_number" [(ngModel)]="phone_number" formControlName="phone_number"
                  placeholder="Enter a phone_number..">
                <div
                  *ngIf="angForm.controls['phone_number'].invalid && (angForm.controls['phone_number'].dirty || angForm.controls['phone_number'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['phone_number'].errors.required">
                    Phone number is required.
                  </div>
                </div>
                </div>
              </div>
            <!-- </div> -->

            <!-- <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="phone_number">Phone number
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="phone_number" [(ngModel)]="phone_number" formControlName="phone_number"
                  placeholder="Enter a phone_number..">
                <div
                  *ngIf="angForm.controls['phone_number'].invalid && (angForm.controls['phone_number'].dirty || angForm.controls['phone_number'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['phone_number'].errors.required">
                    Phone number is required.
                  </div>
                </div>
              </div>
            </div> -->

            <div class="form-group row">
              <label class="col-lg-12 col-form-label" for="address">Address <span class="text-danger">*</span>
              </label>
              <div class="col-lg-12">
                <textarea class="form-control" id="address" rows="5" [(ngModel)]="address" formControlName="address"
                  placeholder="Address?"></textarea>
                <div
                  *ngIf="angForm.controls['address'].invalid && (angForm.controls['address'].dirty || angForm.controls['address'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['address'].errors.required">
                    Suggestions is required.
                  </div>
                </div>
              </div>
            </div>

             <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="kota">Kota
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <input type="text" class="form-control" id="kota" formControlName="kota"
                  placeholder="Enter a Kota.." [(ngModel)]="kota">
                <div
                  *ngIf="angForm.controls['kota'].invalid && (angForm.controls['kota'].dirty || angForm.controls['name'].touched)"
                  class="text-danger fs-13 mt-1">
                  <div *ngIf="angForm.controls['kota'].errors.required">
                    Kota is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label" for="template_option">Template Option
                <span class="text-danger">*</span>
              </label>
              <div class="col-lg-8">
                <select class="form-control default-select" id="template_option" formControlName="template_option" [(ngModel)]="template_option">
                  <option value="">Please select</option>
                  <option value="1">Template 1</option>
                  <option value="2">Template 2</option>
                  <option value="3">Template 3</option>
                  <option value="4">Template 4</option>
                  <option value="5">Template 5</option>
                </select>
                <div
                  *ngIf="angForm.controls['template_option'].invalid && (angForm.controls['template_option'].dirty || angForm.controls['template_option'].touched)"
                  class="text-danger fs-13 mt-1">

                  <div *ngIf="angForm.controls['template_option'].errors.required">
                    Select template_option.
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Uplod Logo
              </label>
              <div class="col-lg-8">
                  <input type="file" class="form-control" name="fileimage" id="fileimage" (change)="onFileSelected($event)">
              </div>
            </div>


            <div class="form-group row">
              <div class="col-lg-8 ml-auto">
                <!-- <button type="submit" class="btn btn-primary"
                  [disabled]="angForm.pristine || angForm.invalid">Submit</button> -->
                <!-- <button (click)="onSubmit()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                <button (click)="saveTutorial()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- END FORM -->
  </div>
</ng-template>


<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
