import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';


export interface Tutorial {
  id?: any;
  idcleansing?: string,
  no_invoice?: string,
  tanggal_tagihan?: string,
  kode_tagihan?: string,
  jenis_tagihan?: string,
  nama_tagihan?: string,
  jumlah_tagihan?: string,
  sisa_tagihan?: string,
  description?: string,
  is_status_tagihan?: string,
  createdAt?: string,
  airwaybill?: string,
  actual_weight?: string,
  nameshipers?: string,
  namevendors?: string,
  full_name?: string,
  shipper_id?: string,
  nameconsignee?: string,

  manifest_date?: string,
  invoice_no?: string,
  mawb?: string,
  flight_number?: string,
  vendor_id?: string,
  note?: string,
  status?: string,
  // namevendors?: string,
  // name?: string,
  // description?: string,
  // note?: string,
  // published?: boolean;
  action?: string;
}

@Component({
  selector: 'app-rp-tagihan',
  templateUrl: './rp-tagihan.component.html',
  styleUrls: ['./rp-tagihan.component.css']
})
export class RpTagihanComponent implements OnInit {
  // exampleData;
  // public exampleData: Array<Select2OptionData>;
  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 500, 1000, 2000, "ALL"];
  isCreated:boolean = true;
  // vcombo: any;
  // vcombo:any = [];
  vcombos:any = [];

  vcomboss:any = [];

  manifest_date = '';
  vendor_id = '';
  shipper_id= '';
  no_invoice= '';
  tanggal_tagihan= '';
  airwaybill= '';

  //Select2

  // public options: Options;
  // public options2: Options;

  constructor(private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private tokenStorage: TokenStorageService
    ) { }

    usersData:any = {};
    rolesString:any = '';
    isAdmin:boolean = false;
    isSuperadmin:boolean = false;
    isCustomer:boolean = false;
    isAgen:boolean = false;
    getUserData() {
      this.tokenStorage.getUsers().then(async res => {
        this.usersData = res;
        // console.log(res)
        this.usersData.role.forEach((e:any) => {
        this.rolesString = this.rolesString+e.name+', ';
        });
        // console.log(this.rolesString)
        if(this.rolesString.includes('Admin')) {
          this.isAdmin = true;
        }
        if(this.rolesString.includes('Superadmin')) {
          this.isSuperadmin = true;
        }
        if(this.rolesString.includes('Agen')) {
          this.isAgen = true;
        }
        // console.log(this.isAdmin)
      })
    }

  ngOnInit(): void {
    this.retrieveTutorials();
    // this.retrieveCombodata();
    this.retrieveCombodatavendors();
    this.retrieveCombodatashippers();
    this.getUserData();
  }

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
		this.modalService.open(content);
	}

  openfilter(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.manifest_date = '';
    this.vendor_id = '';
    this.tanggal_tagihan = '';
    this.no_invoice = '';
    this.shipper_id = '';
    this.airwaybill = '';

		this.modalService.open(content, { size: 'lg' });
	}

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    params.tanggal_tagihan = this.tanggal_tagihan;
    params.no_invoice = this.no_invoice;
    params.airwaybill = this.airwaybill;
    params.shipper_id = this.shipper_id;
    params.vendor_id = this.vendor_id;
    // params.manifest_date = this.manifest_date;

    this.tutorialService.getAlls('tagihans/listview', params)
    .subscribe(
      response => {
        const { data, totalItems } = response;
        this.tutorials = data;
        this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatashippers(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('shippers/viewshipper', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcombos = data;
        // console.log(this.vcombo, 'this.vcombo');
        // this.count = totalItems;
        // console.log(response);
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  // retrieveCombodata(): void {
  //   const params = this.getRequestParams(this.title, this.page, this.pageSize);

  //   // this.tutorialService.postData('typeitems/create', data)
  //   this.tutorialService.getAlls('vendors/view', params)
  //   .subscribe(
  //     response => {
  //       const { data } = response;
  //       this.vcombo = data;
  //     },
  //     error => {
  //       console.log(error);
  //     });
  // }

  retrieveCombodatavendors(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    // this.tutorialService.postData('typeitems/create', data)
    this.tutorialService.getAlls('vendors/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboss = data;
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.modalService.dismissAll();
    this.retrieveTutorials();
  }

  async downloadExcel() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    // params.vendor_id = this.vendor_id;
    // params.manifest_date = this.manifest_date;
    // console.log(params, 'params')
    params.tanggal_tagihan = this.tanggal_tagihan;
    params.no_invoice = this.no_invoice;
    params.airwaybill = this.airwaybill;
    params.shipper_id = this.shipper_id;
    params.vendor_id = this.vendor_id;

    this.tutorialService.postData('tagihans/downloadexcel', params)
      .subscribe(
        async response => {
          // console.log(response, 'resp');

              // var data = response.data,
        // blob = new Blob([data], { type: response.headers('content-type') })
        // var file = new Blob([response], {
        //     type: 'application/vnd.ms-excel'
        // });
        // var fileURL = URL.createObjectURL(file);
        var anchor = document.createElement('a');
        anchor.href = 'https://apiexpl.hallo.or.id/uploads/files/tagihans/'+response.message;
        // anchor.href = 'http://localhost:7777/uploads/files/tagihans/'+response.message;
        anchor.target="_blank";
        anchor.click();

        this.presentToast('Generate Excel Tagihan Success', 'success');
          this.modalService.dismissAll();
          this.retrieveTutorials();
        },
        async error => {
          // console.log(error);
          this.presentToast(error.error.errors, 'error');
        });

  }

  fadd_role: Tutorial = {
    manifest_date:'',
    invoice_no:'',
    mawb:'',
    flight_number:'',
    vendor_id:'',
    note:'',
    status:'',
    // description: '',
    // published: false
  };

  submitted!:boolean;
  saveTutorial(): void {
    const data = {
      manifest_date: this.fadd_role.manifest_date,
      invoice_no: this.fadd_role.invoice_no,
      mawb: this.fadd_role.mawb,
      flight_number: this.fadd_role.flight_number,
      vendor_id: this.fadd_role.vendor_id,
      note: this.fadd_role.note,
      status: this.fadd_role.status
    };
    // console.log(data, 'data');

    if(this.isCreated) {
      this.tutorialService.postData('manifests/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('manifests/update/'+this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal:any, tutorial: any, action:any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.manifest_date = tutorial.manifest_date;
    this.fadd_role.invoice_no = tutorial.invoice_no;
    this.fadd_role.mawb = tutorial.mawb;
    this.fadd_role.flight_number = tutorial.flight_number;
    this.fadd_role.vendor_id = tutorial.vendor_id;
    this.fadd_role.note = tutorial.note;
    this.fadd_role.status = tutorial.status;
    this.fadd_role.action = action;
  }

  deleteUser(data:any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      // const sendataid = {
      //   id: data.id,
      // };
      this.tutorialService.delete(`manifests/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            // this.fadd_role = {};
            this.presentToast(response.message, 'success');
            // this.submitted = true;
            // this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
      // console.log(conf, data.id)
    }
  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 6000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 6000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
